import './AttendanceForm.css';

import React from "react";
import { Link } from 'react-router-dom';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import { render } from "react-dom";



class AttendanceForm extends React.Component<{ message: string }, { show: boolean }> {
render() {
return (
<div>
   <div className="mainBox">
      <div className="main-heading">Attendance Form</div>
      <div>
         <input className="inputField" type="text" name="userName" id="userName" placeholder="Name"  />
      </div>
      <div>
         <input className="inputField" type="text" name="phoneNumber" id="phoneNumber" placeholder="Phone_number" />
      </div>
      <div>
         <input className="inputField" type="text" name="emailId" id="emailId" placeholder="Email-id" />
      </div>
      <div>
         <input className="inputField" type="date" name="date" id="date" placeholder="Date" />
      </div>
      <div className="sub-cancle-btn">
         <div className="sub-btn"><button name="submit" className="attendance-btn-main ">Submit</button></div>
         <div className="cancle-btn"><button className="cancle-main ">Cancle</button> </div>
      </div>
   </div>
</div>
);
}
}
export default AttendanceForm;