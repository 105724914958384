// src/components/ContactUs.tsx
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store/store';
import { updateFormData, updateErrors, submitContactForm } from '../slices/contactSlice';
import '../Header.css';
import '../Common.css';
import '../HomePage.css';
import '../ContactUs.css';

const ContactUs: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { formData, errors, responseMessage, loading } = useSelector((state: RootState) => state.contact);

  const validateForm = () => {
    const formErrors = { ...errors };
    let formIsValid = true;

    if (!formData.fullName) {
      formIsValid = false;
      formErrors.fullName = 'Full Name is required';
    } else {
      formErrors.fullName = '';
    }

    if (!formData.phoneNumber) {
      formIsValid = false;
      formErrors.phoneNumber = 'Phone Number is required';
    } else {
      formErrors.phoneNumber = '';
    }

    if (!formData.email) {
      formIsValid = false;
      formErrors.email = 'Email is required.';
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(formData.email)) {
      formIsValid = false;
      formErrors.email = 'Please enter a valid email address';
    } else {
      formErrors.email = '';
    }

    if (!formData.text) {
      formIsValid = false;
      formErrors.text = 'Text is required';
    } else {
      formErrors.text = '';
    }

    dispatch(updateErrors(formErrors));
    return formIsValid;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch (submitContactForm(formData)as any);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    dispatch(updateFormData({ name, value }));
  };

  return (
    <div>
      <form onSubmit={handleSubmit} id="book-call-section">
        <div className="main main-color">
          <div className="train-main1" id="book-call-section">
            <div className="tyt-heading tyt-heading-text mainHeading">Book a call</div>
            <div className="tyt-heading-line-main">
              <hr className="tyt-heading-line1" />
            </div>
            <div>
              <div className="container para-text" id="contact-form">
                <div>
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    placeholder="Full name.."
                    value={formData.fullName}
                    onChange={handleInputChange}
                  />
                  <span className="error">{errors.fullName}</span>
                </div>
                <div>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email address.."
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  <span className="error">{errors.email}</span>
                </div>
                <div>
                  <input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Phone no.."
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                  />
                  <span className="error">{errors.phoneNumber}</span>
                </div>
                <div>
                  <textarea
                    id="text"
                    name="text"
                    placeholder="Text here...."
                    value={formData.text}
                    onChange={handleInputChange}
                  />
                  <span className="error">{errors.text}</span>
                </div>
                <div className="btn">
                  <button className="btn-main" type="submit" id="contactBtn" disabled={loading}>
                    {loading ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
                {responseMessage && (
                  <div id="contactMsg" className="contactMsg">
                    {responseMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
