import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "./Header.css";
import "./Common.css";
import "./HomePage.css";

class Cookies extends Component {
  componentDidMount() {
    const allowBtn = document.getElementById("allowCookie");
    if (allowBtn) {
      allowBtn.addEventListener("click", this.handleAllowClick);
    }

    const personaliseCk = document.getElementById("personalise-ck");
    if (personaliseCk) {
      personaliseCk.addEventListener("click", this.handlePersonaliseClick);
    }

    const allowEssCk = document.getElementById("allowEssCk");
    if (allowEssCk) {
      allowEssCk.addEventListener("click", this.handleAllowEssClick);
    }
  }

  componentWillUnmount() {
    const allowBtn = document.getElementById("allowCookie");
    if (allowBtn) {
      allowBtn.removeEventListener("click", this.handleAllowClick);
    }

    const personaliseCk = document.getElementById("personalise-ck");
    if (personaliseCk) {
      personaliseCk.removeEventListener("click", this.handlePersonaliseClick);
    }

    const allowEssCk = document.getElementById("allowEssCk");
    if (allowEssCk) {
      allowEssCk.removeEventListener("click", this.handleAllowEssClick);
    }
  }

  handleAllowClick = () => {
    this.checkCookie();
    const cookiePopup = document.getElementById("cookiePopup");
    if (cookiePopup) {
      cookiePopup.remove();
    }
  };

  handlePersonaliseClick = () => {
    const essCkElements = document.querySelectorAll(".ess-ck");
    essCkElements.forEach((element) => {
      const elem = element as HTMLElement;
      elem.style.display = "block";
    });

    const cookieContainer = document.querySelector(".cookie-container");
    if (cookieContainer) {
      cookieContainer.remove();
    }
  };

  handleAllowEssClick = () => {
    const essCkElements = document.querySelectorAll(".ess-ck");
    essCkElements.forEach((element) => {
      element.remove();
    });
  };

  setCookie(cname: string, cvalue: string, exdays: number) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString(); // Use toUTCString instead of toGMTString
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  displayCookiePopup() {
    let user = this.getCookie("lokemcookie");
    if (user !== "") {
      const cookiePopup = document.getElementById("cookiePopup");
      if (cookiePopup) {
        cookiePopup.remove();
      }
    } else {
      const cookiePopup = document.getElementById("cookiePopup");
      if (cookiePopup) {
        cookiePopup.style.display = "block";
      }
    }
  }

  checkCookie() {
    let user = this.getCookie("lokemcookie");
    if (user !== "") {
      const cookiePopup = document.getElementById("cookiePopup");
      if (cookiePopup) {
        cookiePopup.remove();
      }
    } else {
      this.setCookie("lokemcookie", "true", 30);
      const cookiePopup = document.getElementById("cookiePopup");
      if (cookiePopup) {
        cookiePopup.style.display = "block";
      }
    }
  }

  render() {
    return (
      <>
        <div>
          <div id="cookiePopup" className="cookiePopup">
            <div className="cookie-container">
              <div className="cookie-content">
                <div className="cookie-para">
                  This website uses cookies to ensure you get the best browsing experience. For more information, read our{" "}
                  <a href="privacyPolicy" target="_blank" className="ck-privacy-policy">
                    privacy policy.
                  </a>
                </div>
                <div className="accept-content">Click below on 'Accept' to consent or 'Personalise' to manage your preferences.</div>
              </div>
              <div className="cookie-btn-container">
                <div className="allow-btn-right">
                  <button className="cookie-btns lokem-button" id="allowCookie">
                    Accept
                  </button>
                </div>
                <div>
                  <button className="cookie-btns lokem-button" id="personalise-ck">
                    Personalise
                  </button>
                </div>
              </div>
            </div>
            <div id="cookiePopup1" className="ess-ck" style={{ display: "none" }}></div>
          </div>
        </div>
      </>
    );
  }
}

export default Cookies;