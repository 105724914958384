import React from "react";
import { render } from "react-dom";
import { createStore } from "redux";
import "./index.css";
import Header from "./Header";
import HomePage from "./HomePage";
import AboutUs from "./AboutUs";
import Footer from "./Footer";
import Services from "./Services";
import AttendanceForm from "./AttendanceForm";
import Admin from "./Admin";
import AdminLogin from "./AdminLogin";
import Cookies from "./Cookies";
import WorkFlow from "./WorkFlow";
import CookiePolicy from "./CookiePolicy";
import TermsAndConditions from "./TAndC";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store/store';
import ContactUs from './component/ContactUs';

function App() {
  return (
    <>
    <Provider store={store}>
       <Header message="" />
      
      <Routes>
        
        <Route path="/AttendanceForm" element={<AttendanceForm message="" />} />
        <Route path="/" element={<HomePage message="" />} />
        <Route path="/AboutUs" element={<AboutUs message=""/>} />
        <Route path="/contactUs" element={<ContactUs/>} />
        <Route path="/Services"  element={<Services/>}  />
        
        <Route path="/AdminLogin" element={<AdminLogin />} />
        <Route path="/Admin" element={<Admin message="" />} />
        <Route path="/CookiePolicy"  element={<CookiePolicy />}  />
        <Route path="/Cookies"  element={<Cookies />}  />
        <Route path="/WorkFlow"  element={<WorkFlow />}  />
        <Route path="/TermsAndConditions"  element={<TermsAndConditions />}  />
        
       
      </Routes>
      <Footer  />
      </Provider>
    </>
  );
}
export default App;





