import React from "react";
import { render } from "react-dom";
import "./Header.css";
import { Link, Router } from "react-router-dom";
import "./HomePage.css";
import '../node_modules/font-awesome/css/font-awesome.min.css';
import './Common.css';
import logo from './immage.jpg';
import logo1 from './AboutUs.jpg';
import './AboutUs.css';



class AboutUs extends React.Component<{ message: string }, { show: boolean }> {
    render() {


  return (
    
    <div className="top-page">
     
      <div className="train-your-main about-main">
         <div className="tyt-col1">
            <div className="tyt-heading-main">
               <div className=" mainHeading"><img src={logo1} className="top-image" alt="logo"/></div>
               <div className="tyt-heading-line-main">
               
               </div>
            </div>
         </div>
      </div>
      <div className="main1">
      <div className="main-respons mainSubHeading">Responsibilities
      <div className="train-your-main aboutus-main">
         <div className="about-img"><img src={logo} className="about-logo-img" alt="logo"/></div>
         <div className="main-about-respns">
            
            <div className="about-para-text para-text">
               <p>Write well designed,testable,efficient code by using best software development practices.
                  Create website layout/user interface by using standard HTML/CSS practices.
               </p>
               <p>Integrate data from various back-end services and databases.</p>
               <p>Gather and refine specifications and requirements based on technical needs .</p>
               <p>Create and maintain software documentation.
                  Be responsible for maintaining, expanding, and scaling our site.
                  Stay plugged into emerging technologies/industry trends and apply them into operations and activities.
                  Cooperate with web designers to match visual design intent.
               </p>
               </div>
            </div>
         </div>
      </div>
</div>
</div>
)
  }  
    };
    
    export default AboutUs;