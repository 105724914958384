import React from "react";
import { Link } from 'react-router-dom';
import { render } from "react-dom";

import './Footer.css';


function App() {
  return (
    <div>
    <footer className="main-footer footer">
    <div className="menu-items-footer-container">
      <ul className="menu-items-footer" id="menu-items-footer">
        <Link to='/'><li>Home</li></Link>
        <Link to='/AboutUs'><li> AboutUs</li></Link>
        <Link to='/Services'><li>Services</li></Link>
        <Link to='/Contactus'><li>ContactUs</li></Link>
      </ul>
    </div>
    <div className="CookiePolicyText" id="CookiePolicyText"><Link to="/CookiePolicy"  >CookiePolicy</Link></div>
    <div className="CookiePolicyText" id="TermsAndConditionsText"><Link to="/termsAndConditions"  >TermsAndConditions</Link></div>

    <div className="copyRightText" id="copyRightText">Copyright © 2023</div>
   
  </footer>
  </div>
     );
}

export default App;
