import React, { useState } from 'react';
import './WorkFlow.css';

interface MousePosition {
  x: number;
  y: number;
}

function SoftwareDevelopmentCycle() {
  const [hoveredPhase, setHoveredPhase] = useState<string | null>(null);
  const [mousePosition, setMousePosition] = useState<MousePosition>({ x: 0, y: 0 });
  const [popupColor, setPopupColor] = useState<string>('');
  const [popupPosition, setPopupPosition] = useState<{ top: number; left: number } | null>(null);

  const handleMouseEnter = (phase: string, color: string, event: React.MouseEvent) => {
    setHoveredPhase(phase);
    setMousePosition({ x: event.clientX, y: event.clientY });
    setPopupColor(color); // Set the popup color based on the hovered phase
    determinePopupPosition(event.clientX, event.clientY); // Pass only the mouse coordinates
  };

  const handleMouseLeave = () => {
    setHoveredPhase(null);
    setPopupColor('');
    setPopupPosition(null); // Clear popup position
  };

  const determinePopupPosition = (mouseX: number, mouseY: number) => {
    const offset = 20; // Offset for popup positioning
    const popupWidth = 250; // Width of the popup
    let position = { top: mouseY, left: mouseX };
    
    const isMobile = window.innerWidth <= 900;
    
    if (isMobile) {
      // Mobile view: set popup below SVG
      const svgElement = document.querySelector('.circle-diagram');
      if (svgElement) {
        const svgRect = svgElement.getBoundingClientRect();
        position = {
          top: svgRect.bottom + offset, // Position below SVG with offset
          left: svgRect.left + (svgRect.width / 2) - (popupWidth / 2) // Centered below SVG
        };
      }
    } else {
      // Desktop view: adjust based on hovered phase
      const svgElement = document.querySelector('.circle-diagram');
      if (svgElement) {
        const svgRect = svgElement.getBoundingClientRect();
        if (['Planning', 'Analysis', 'Design'].includes(hoveredPhase)) {
          position.left = svgRect.right + offset; // Right side of SVG for these phases
        } else if (['Implementation', 'Testing', 'Maintenance'].includes(hoveredPhase)) {
          position.left = svgRect.left - popupWidth - offset; // Left side of SVG for these phases
        }
      }
    }
  
    setPopupPosition(position); // Update popup position
  };
  
  

  return (
    <div className="software-cycle-container">
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <svg viewBox="-40 -40 310 300" width="650" height="650" className="circle-diagram">
          {/* Phases in the SVG */}
          <g onMouseEnter={(e) => handleMouseEnter('Planning', '#e74c3c', e)} onMouseLeave={handleMouseLeave}>
            <path d="M 110 110 L 110.00000000000001 -40 A 150 150 0 0 1 239.9038105676658 35 Z" fill="#e74c3c" stroke="white" strokeWidth="2"></path>
            <text x="160" y="10" fill="white" fontSize="10" textAnchor="middle">1.Planning</text>
          </g>
          <g onMouseEnter={(e) => handleMouseEnter('Analysis', '#34495e', e)} onMouseLeave={handleMouseLeave}>
            <path d="M 110 110 L 239.9038105676658 35 A 150 150 0 0 1 239.9038105676658 184.99999999999997 Z" fill="#34495e" stroke="white" strokeWidth="2"></path>
            <text x="220" y="110" fill="white" fontSize="10" textAnchor="middle">2.Analysis</text>
          </g>
          <g onMouseEnter={(e) => handleMouseEnter('Design', '#3498db', e)} onMouseLeave={handleMouseLeave}>
            <path d="M 110 110 L 239.9038105676658 184.99999999999997 A 150 150 0 0 1 110.00000000000004 260 Z" fill="#3498db" stroke="white" strokeWidth="2"></path>
            <text x="150" y="200" fill="white" fontSize="10" textAnchor="middle">3.Design</text>
          </g>
          <g onMouseEnter={(e) => handleMouseEnter('Implementation', '#1abc9c', e)} onMouseLeave={handleMouseLeave}>
            <path d="M 110 110 L 110.00000000000001 260 A 150 150 0 0 1 -19.903810567665772 185.00000000000006 Z" fill="#1abc9c" stroke="white" strokeWidth="2"></path>
            <text x="50" y="200" fill="white" fontSize="10" textAnchor="middle">4.Implementation</text>
          </g>
          <g onMouseEnter={(e) => handleMouseEnter('Testing', '#f1c40f', e)} onMouseLeave={handleMouseLeave}>
         <path d="M 110 110 L -19.903810567665772 185.00000000000006 A 150 150 0 0 1 -19.903810567665857 35.000000000000085 Z" fill="#f1c40f" stroke="white" strokeWidth="2"></path>
          <text x="-10" y="90" fill="white" fontSize="10" textAnchor="middle">Testing</text>
          <text x="-10" y="100" fill="white" fontSize="10" textAnchor="middle">&</text>
           <text x="-6" y="110" fill="white" fontSize="10" textAnchor="middle">Integration</text>
        </g>
          <g onMouseEnter={(e) => handleMouseEnter('Maintenance', '#e67e22', e)} onMouseLeave={handleMouseLeave}>
            <path d="M 110 110 L -19.9038105676658 34.999999999999986 A 150 150 0 0 1 109.99999999999997 -40 Z" fill="#e67e22" stroke="white" strokeWidth="2"></path>
            <text x="55" y="15" fill="white" fontSize="10" textAnchor="middle">6.Maintenance</text>
          </g>

          <circle cx="110" cy="110" r="70" fill="white"></circle>
          <circle cx="110" cy="110" r="60" fill="lightgray"></circle>
          <text x="110" y="95" fontSize="12" textAnchor="middle" fill="black">The Software</text>
          <text x="110" y="110" fontSize="12" textAnchor="middle" fill="black">Development</text>
          <text x="110" y="125" fontSize="12" textAnchor="middle" fill="black">Cycle</text>
        </svg>
      </div>

      {/* Popup display on hover */}
      {hoveredPhase && popupPosition && (
        <div
          className="hover-popup"
          style={{
            top: popupPosition.top,
            left: popupPosition.left,
            backgroundColor: popupColor,
          }}
        >
          <h2>{hoveredPhase} Phase</h2>
          <p>{getPhaseContent(hoveredPhase)}</p>
        </div>
      )}
    </div>
  );
}

function getPhaseContent(phase: string) {
  switch (phase) {
    case 'Planning':
      return 'In the Planning phase, project goals, scope, and timeline are determined.';
    case 'Analysis':
      return 'During Analysis, requirements are gathered and documented.';
    case 'Design':
      return 'In the Design phase, system architecture and components are outlined.';
    case 'Implementation':
      return 'Implementation involves coding and developing the application.';
    case 'Testing':
      return 'Testing ensures that the system works as intended and meets the requirements.';
    case 'Maintenance':
      return 'Maintenance involves monitoring, updating, and fixing bugs after deployment.';
    default:
      return '';
  }
}

export default SoftwareDevelopmentCycle;
