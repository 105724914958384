import logo from "./logo_final.png";
import React from "react";
import { render } from "react-dom";
import "./Header.css";
import "./Common.css";
import { Link, Router } from "react-router-dom";
 
type MyState = {
  show: false; // like this
};
class Header extends React.Component<{ message: string }, { show: boolean }> {
  state: MyState = {
    // optional second annotation for better type inference
    show: false,
  };
  increment = () => {
    // like this
    this.setState((state) => ({
      show: !this.state.show,
    }));
  };
  render() {
    return (
      <>
        <header>
          <div className="header-container">
            <div className="logo-container">
              <img src={logo} className="logo-img" alt="logo" />
            </div>
            <div className="menu-container">
              <div
                className="bars-icon"
                id="bars-icon"
                onClick={() => this.increment()}
              >
                <i className="fa fa-bars"></i>
              </div>
            </div>
            {this.state.show ? (
              <div className="menu-items-container">
                <ul className="menu-items" id="menu-items">
                  <Link to="/">
                    <li>Home</li>
                  </Link>
                  <Link to="/AboutUs">
                    <li> AboutUs</li>
                  </Link>
                  <Link to="/Services">
                    <li>Services</li>
                  </Link>
                  <Link to="/Contactus">
                    <li>ContactUs</li>
                  </Link>
                 
                </ul>
                
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </header>
      </>
    );
  }
}

export default Header;
