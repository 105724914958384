import React from "react";
import "./AdminLogin.css";

interface FormState {
  User: string;
  psw: string;
  
  errors: {
    User: string;
    psw: string;
    
  };
}
class AdminLogin extends React.Component<{},any, FormState> {
  constructor(props:{}) {
    super(props);

    this.state = {
      User: '',
      psw: '',
     
      errors: {
        User: '',
        psw: '',
       
      },
    };
  }
  validateForm = () => {
    let errors: Partial<FormState> = {}
    let formIsValid = true;

    // Validate Full Name
    if (!this.state.User) {
      formIsValid = false;
      errors.User = 'User Name is required.';
    }
       

    // Validate Last Name
    if (!this.state.psw) {
      formIsValid = false;
      errors.psw = 'Password is required.';
    }

    
    this.setState({ errors });
    return formIsValid;
  };

 
  // Handle form submission
  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (this.validateForm()) {
      // Form is valid, you can submit the data or take appropriate action
      // For now, we'll just log the form data
      console.log('Form submitted:', this.state);
    } else {
      // Form is not valid, do nothing or display an error message
      console.log('Form has validation errors.');
    }
  };

  // Handle input changes
  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
    render(){
      const { errors } = this.state;
      return (

<div>

<form onClick={this.handleSubmit} >
  <div className="admin-loginForm" id="LoginForm" >
    <h1>Admin Login</h1>
    
    <div style={{marginBottom:"30px"}}>

    <label htmlFor="User "><b>User Name</b></label>
    <input type="text" placeholder=" User Name" name="User" required />
    <span className="error">{errors.User}</span></div>
   <div>
    <label htmlFor="psw"><b>Password</b></label>
    <input type="text" placeholder=" Password" name="psw" required />
    <span className="error">{errors.psw}</span> 
    </div>
    <div className="loginDiv">
      <button type="submit" className="loginbtn lokem-button">Login</button>
    </div>
  </div>
  </form>
  </div>
 );
    }

    }
  


 
export default AdminLogin;