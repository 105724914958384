import React from "react";
import { render } from "react-dom";
import '../node_modules/font-awesome/css/font-awesome.min.css';
import "./AdminTopHeader.css";
import "./Admin.css";
import { Link, Router } from "react-router-dom";

class AdminTopHeader extends React.Component<{},any> {
    render() {
        return (
          <>



<nav>
    <div className="sidebar-button">
      <i className="fa fa-bars sidebarBtn"></i>
      <span className="dashboard">
      Welcome to admin
      </span>
    </div>
  </nav>

  </>
  );
} 
}  
                
                              
export default AdminTopHeader;
