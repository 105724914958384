import logo from "./logo_final.png";
import React from "react";
import "./HomePage.css";
import "./Header.css";
import { Link, Router } from "react-router-dom";
import '../node_modules/font-awesome/css/font-awesome.min.css';
import './Services.css';
/*import './Common.css';*/
import ContactUs from './component/ContactUs';



class Servicec extends React.Component<{},any> {
    render(){
  
      return (
         <div>
          <div>  
    <div className="main main1">
     <div className="section-content1">
          <div className="mainHeading1 mainHeading">
                <div> Full service web apps</div> 
             <div className="main-lineup1"></div>  
          </div>
       </div>
       <div className="main-para para-text">Our full-services web app package is excellent for greenfield applications like creating a new product or digital transformation from start to finish. we make it extremely easy to get what you expect by speaking in a language you understand.Then,we take care of the inner workings of the highly complex practices working together behind the scenes.
       </div>
    </div>

<div className="main">
 <div className=" back-color">
    <div className="section-content2">
          <div className="mainHeading2 mainHeading">Specialist services
          <div className="main-lineup2"></div></div>
       </div>
       <div className="main-para1 para-text">Our specialist services are excellent for those that know they need something in particular and want to get techie.Specialist services are for those who know what resource they need and can obtain just that thing.
       </div>

 

 <div className=" back-color">
    <div className="section-content3-row1">
       <div className="frontend-col1">
          <div className="col1-total">
             <div className="frontend-heading mainSubHeading">Frontend engineering</div>
             <div className="frontent-para para-text">Web development that focuses on the presentation aspect of you website,enabing your users to visualise date,interact with it and connect.</div>
             {/*<div className="text">VIEW FRONTEND ENGINEERING SERVICES</div>*/}
          </div>
       </div>
       
       <div className="backend-col2">
          <div className="col2-total">
             <div className="backend-heading mainSubHeading">Backend engineering</div>
             <div className="backend-para para-text">Focuses on processing data for your website outside of the user's devices in servers,making them available to connect.</div>
             {/*<div className="text-one">VIEW BACKEND ENGINEERING SERVICES</div>*/}
          </div>
       </div>
    </div>
 </div>


 <div className=" back-color">
    <div className="section-content3-row1">
       <div className="frontend-col1">
          <div className="col1-total">
             <div className="frontend-heading mainSubHeading">Fullstack engineering</div>
             <div className="frontent-para para-text">Fullstack is a combination of Frontend,Backend and a touch of DevOps engineering.Nearly all websites will require this.</div>
          </div>
       </div>
       <div className="backend-col2">
          <div className="col2-total">
             <div className="backend-heading mainSubHeading">DevOps engineering</div>
             <div className="backend-para para-text">Automate software delivery workflows to simplify deployments of upgrades and updates,providing faster updates to your softwre.
             </div>
          </div>
          </div>
       </div>
    </div>
 </div>
</div>
</div>
<div><ContactUs/></div> 
</div>
      );
    }
    }
  


 
export default Servicec;