// src/redux/slices/contactSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

interface ContactState {
  formData: {
    fullName: string;
    email: string;
    phoneNumber: string;
    text: string;
  };
  errors: {
    fullName: string;
    email: string;
    phoneNumber: string;
    text: string;
  };
  responseMessage: string;
  loading: boolean;
}

const initialState: ContactState = {
  formData: {
    fullName: "",
    email: "",
    phoneNumber: "",
    text: "",
  },
  errors: {
    fullName: "",
    email: "",
    phoneNumber: "",
    text: "",
  },
  responseMessage: "",
  loading: false,
};

// Thunk for form submission
export const submitContactForm = createAsyncThunk(
  "contact/submitForm",
  async (data: ContactState["formData"], { rejectWithValue }) => {
    try {
      console.log(process.env.REACT_APP_API_URL);
      const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8014";
      const response = await fetch(`${apiUrl}/api/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if (!response.ok) throw new Error(result.error || "Submission failed");
      return result.message;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    updateFormData(
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) {
      state.formData[action.payload.name as keyof ContactState["formData"]] =
        action.payload.value;
    },
    updateErrors(state, action: PayloadAction<ContactState["errors"]>) {
      state.errors = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitContactForm.pending, (state) => {
        state.loading = true;
        state.responseMessage = "";
      })
      .addCase(submitContactForm.fulfilled, (state, action) => {
        state.loading = false;
        state.responseMessage = action.payload;
        state.formData = initialState.formData;
      })
      .addCase(submitContactForm.rejected, (state, action) => {
        state.loading = false;
        state.responseMessage = action.payload as string;
      });
  },
});

export const { updateFormData, updateErrors } = contactSlice.actions;
export default contactSlice.reducer;
