import logo from "./logo_final.png";
import { render } from "react-dom";
import "./Header.css";
import "./Common.css";
import "./HomePage.css";
import '../node_modules/font-awesome/css/font-awesome.min.css';
import ContactUs from './component/ContactUs';
import { Link, Router } from "react-router-dom";
import React from "react";
import WorkFlow from "./WorkFlow";






class HomePage extends React.Component<{ message: string }, { show: boolean }> {
   render() {
 
    return (
      <div>
        <div>
      <div className="mainDiv">
        <div className="rainbowGradient">
          <div className="innerSquare">
            <div className="subDiv">
              <div>
                <div className="text-heading mainHeading">
                <div> We create web applications</div> 
                  <div>
                  <hr className="course-heading-bottom-line" />
                </div>
                <div className="text-para para-text">
                  We build your ideas for web applications, products, tools, and
                  dashboards to make them a reality
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>   
      
      <div className="mainDiv sec1-color">
        <div className="childDiv">
          <div className="image-left">
            <i className="fa webapps">&#xf268;</i>
            <i className="fa webapps">&#xf08c;</i>
            <i className="fa webapps">&#xf26b;</i>
            <i className="fa webapps">&#xf230;</i>
          </div>
          <div className="text-rightside">
            <div className="section-text">
              <div>
                <div className="header-hrline">
                  <div className="text-header mainSubHeading">
                    What's a web application?
                  </div>
                  <div>
                    <hr className="under-line1 " />
                  </div>
                </div>
                <div className="text-para para-text">
                  If you've ever used something like Facebook, LinkedIn, and
                  Twitter, you already know what a web application is. Unlike
                  regular static websites, web applications change and move with
                  interaction to allow communication, collaboration and shape
                  data around the user.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mainDiv sec2-color">
        <div className="childDiv direction">
          <div className="image-left">
            <i className="fa webapps">&#xf268;</i>
            <i className="fa webapps">&#xf08c;</i>
            <i className="fa webapps">&#xf26b;</i>
            <i className="fa webapps">&#xf230;</i>
          </div>
          <div className="text-rightside sec2-left">
            <div className="section-text">
              <div>
                <div className="header-hrline">
                  <div className="text-header mainSubHeading">
                    Who needs a web application?
                  </div>
                  <div>
                    <hr className="under-line1" />
                  </div>
                </div>
                <div className="text-para para-text">
                  Suppose you have a business that needs a custom software
                  solution to solve a particular problem, or you have an idea
                  for an app. In that case, building a web application is one
                  way to meet those goals.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mainDiv sec3-color">
        <div className="header  mainSubHeading">Our Work Flow</div>
        <div><WorkFlow/></div> 
      </div>
    </div>
    
<div><ContactUs/></div> 
  
   </div>
);
} 
}  
                



     
           
                              

export default HomePage;
