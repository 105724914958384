import React from "react";
import { Link, Router } from "react-router-dom";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "./tAndC.css";

class TAndC extends React.Component {
  render() {
    return (
      <>
        <div className="terms-header-top-page">
          <div className="terms-first-container">
            <div className="terms-page-heading">Terms and Conditions</div>
            <div className="terms-page-subHeading">
              Last Updated : (13 May 2022)
            </div>
          </div>
        </div>
        <div className="tc-body-container" id="TermsAndConditions">
          <div className="termsMainDiv">
            <div>
              <h2 className="introductionText">Introduction:</h2>
            </div>
            <div>
              <p>
                These terms and conditions apply between you, the User of this
                Website (including any sub-domains, unless expressly excluded by
                their terms and conditions), and Lokem LTD, the owner and
                operator of this Website. Please read these terms and conditions
                carefully, as they affect your legal rights. Your agreement to
                comply with and be bound by these terms and conditions is deemed
                to occur upon your first use of the Website. If you do not agree
                to be bound by these terms and conditions, you should stop using
                the Website immediately.
              </p>
            </div>
            <div>
              <p>
                In these terms and conditions, User or Users means any third
                party that accesses the Website and is not either (i) employed
                by Lokem LTD and acting in the course of their employment or
                (ii) engaged as a consultant or otherwise providing services to
                Lokem LTD and accessing the Website in connection with the
                provision of such services.
              </p>
            </div>
            <div>
              <p>
                You must be at least 18 years of age to use this Website. By
                using the Website and agreeing to these terms and conditions,
                you represent and warrant that you are at least 18 years of age.
              </p>
            </div>
            <div>
              <p>Intellectual property and acceptable use</p>
            </div>
            <div>
              <ol>
                <li>
                  All Content included on the Website, unless uploaded by Users,
                  is the property of Lokem LTD, our affiliates or other relevant
                  third parties. In these terms and conditions, Content means
                  any text, graphics, images, audio, video, software, data
                  compilations, page layout, underlying code and software and
                  any other form of information capable of being stored in a
                  computer that appears on or forms part of this Website,
                  including any such content uploaded by Users. By continuing to
                  use the Website you acknowledge that such Content is protected
                  by copyright, trademarks, database rights and other
                  intellectual property rights. Nothing on this site shall be
                  construed as granting, by implication, estoppel, or otherwise,
                  any license or right to use any trademark, logo or service
                  mark displayed on the site without the owner's prior written
                  permission
                </li>
                <li>
                  You may, for your own personal, non-commercial use only, do
                  the following:
                  <ol type="a">
                    <li>
                      retrieve, display and view the Content on a computer
                      screen
                    </li>
                  </ol>
                </li>
                <li>
                  You must not otherwise reproduce, modify, copy, distribute or
                  use for commercial purposes any Content without the written
                  permission of Lokem LTD.
                </li>
              </ol>
            </div>
            <div>
              <h2 className="introductionText">Prohibited use</h2>
            </div>
            <div>
              <ol start={4}>
                <li>
                  You may not use the Website for any of the following purposes:
                  <ol type="a">
                    <li>
                      in any way which causes, or may cause, damage to the
                      Website or interferes with any other person's use or
                      enjoyment of the Website;
                    </li>
                    <li>
                      in any way which is harmful, unlawful, illegal, abusive,
                      harassing, threatening or otherwise objectionable or in
                      breach of any applicable law, regulation, governmental
                      order;
                    </li>
                    <li>
                      making, transmitting or storing electronic copies of
                      Content protected by copyright without the permission of
                      the owner.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <div>
              <h2 className="introductionText">Registration</h2>
            </div>
            <div>
              <ol start={5}>
                <li>
                  You must ensure that the details provided by you on
                  registration or at any time are correct and complete.
                </li>

                <li>
                  You must inform us immediately of any changes to the
                  information that you provide when registering by updating your
                  personal details to ensure we can communicate with you
                  effectively.
                </li>

                <li>
                  {" "}
                  We may suspend or cancel your registration with immediate
                  effect for any reasonable purposes or if you breach these
                  terms and conditions.
                </li>

                <li>
                  {" "}
                  You may cancel your registration at any time by informing us
                  in writing to the address at the end of these terms and
                  conditions. If you do so, you must immediately stop using the
                  Website. Cancellation or suspension of your registration does
                  not affect any statutory rights.
                </li>
              </ol>
            </div>
            <div>
              <h2 className="introductionText">Password and security</h2>
            </div>
            <div>
              <ol start={9}>
                <li>
                  {" "}
                  When you register on this Website, you will be asked to create
                  a password, which you should keep confidential and not
                  disclose or share with anyone.
                </li>

                <li>
                  If we have reason to believe that there is or is likely to be
                  any misuse of the Website or breach of security, we may
                  require you to change your password or suspend your account.
                </li>
              </ol>
            </div>
            <div>
              <h2 className="introductionText">
                Availability of the Website and disclaimers
              </h2>
            </div>
            <div>
              <ol start={11}>
                <li>
                  Any online facilities, tools, services or information that
                  Lokem LTD makes available through the Website (the Service) is
                  provided "as is" and on an "as available" basis. We give no
                  warranty that the Service will be free of defects and/or
                  faults. To the maximum extent permitted by the law, we provide
                  no warranties (express or implied) of fitness for a particular
                  purpose, accuracy of the information, compatibility and
                  satisfactory quality. Lokem LTD is under no obligation to
                  update information on the Website.
                </li>

                <li>
                  Whilst Lokem LTD uses reasonable endeavors to ensure that the
                  Website is secure and free of errors, viruses and other
                  malware, we give no warranty or guarantee in that regard and
                  all Users take responsibility for their own security, that of
                  their personal details and their computers.
                </li>

                <li>
                  Lokem LTD accepts no liability for any disruption or
                  non-availability of the Website.
                </li>

                <li>
                  Lokem LTD reserves the right to alter, suspend or discontinue
                  any part (or the whole of) the Website including, but not
                  limited to, any products and/or services available. These
                  terms and conditions shall continue to apply to any modified
                  version of the Website unless it is expressly stated
                  otherwise.
                </li>
              </ol>
            </div>
            <div>
              <h2 className="introductionText">Limitation of liability</h2>
            </div>
            <div>
              <ol start={15}>
                <li>
                  Nothing in these terms and conditions will: (a) limit or
                  exclude our or your liability for death or personal injury
                  resulting from our or your negligence, as applicable; (b)
                  limit or exclude our or your liability for fraud or fraudulent
                  misrepresentation; or (c) limit or exclude any of our or your
                  liabilities in any way that is not permitted under applicable
                  law.
                </li>

                <li>
                  We will not be liable to you in respect of any losses arising
                  out of events beyond our reasonable control.
                </li>

                <li>
                  To the maximum extent permitted by law, Lokem LTD accepts no
                  liability for any of the following:
                  <ol type="a">
                    <li>
                      any business losses, such as loss of profits, income,
                      revenue, anticipated savings, business, contracts,
                      goodwill or commercial opportunities;
                    </li>
                    <li>
                      loss or corruption of any data, database or software;
                    </li>
                    <li>
                      any special, indirect or consequential loss or damage.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>

            <div>
              <h2 className="introductionText">General</h2>
            </div>
            <div>
              <ol start={18}>
                <li>
                  You may not transfer any of your rights under these terms and
                  conditions to any other person. We may transfer our rights
                  under these terms and conditions where we reasonably believe
                  your rights will not be affected.
                </li>

                <li>
                  These terms and conditions may be revised by us from time to
                  time. Such revised terms will apply to the Website from the
                  date of publication. Users should check the terms and
                  conditions regularly to ensure familiarity with the then
                  current version.
                </li>

                <li>
                  These terms and conditions contain the whole agreement between
                  the parties relating to its subject matter and supersede all
                  prior discussions, arrangements or agreements that might have
                  taken place concerning the terms and conditions.
                </li>
                <li>
                  The Contracts (Rights of Third Parties) Act 1999 shall not
                  apply to these terms and conditions and no third party will
                  have any right to enforce or rely on any provision of these
                  terms and conditions.
                </li>

                <li>
                  If any court or competent authority finds that any provision
                  of these terms and conditions (or part of any provision) is
                  invalid, illegal or unenforceable, that provision or
                  part-provision will, to the extent required, be deemed to be
                  deleted, and the validity and enforceability of the other
                  provisions of these terms and conditions will not be affected.
                </li>

                <li>
                  {" "}
                  Unless otherwise agreed, no delay, act or omission by a party
                  in exercising any right or remedy will be deemed a waiver of
                  that, or any other, right or remedy.
                </li>
                <li>
                  This Agreement shall be governed by and interpreted according
                  to the law of England and Wales and all disputes arising under
                  the Agreement (including non-contractual disputes or claims)
                  shall be subject to the exclusive jurisdiction of the English
                  and Welsh courts.
                </li>
              </ol>
            </div>
            <div>
              <h2 className="introductionText">Payments:</h2>
            </div>
            <div>
              <ol start={25}>
                <li>
                  When you make a payment, you agree to use a valid payment
                  method. If you aren’t happy with your content, Lokem does not
                  issue refunds to any content purchased.
                </li>

                <li>
                  Lokem occasionally runs promotions and sales for our content,
                  during which certain content is available at discounted prices
                  for a set period of time. The price applicable to the content
                  will be the price at the time you complete your purchase of
                  the content (at checkout ).
                </li>
                <li>
                  Payments must be received (GBP). Lokem does not enable users
                  to see pricing in other currencies.
                </li>
                <li>
                  You agree to pay the fees for content that you purchase, and
                  you authorize us to charge your debit or credit card for those
                  fees. Lokem works with payment service providers to offer you
                  the most convenient payment methods to keep your payment
                  information secure.
                </li>

                <li>
                  When you make a purchase, you agree not to use an invalid or
                  unauthorized payment method. If your payment method fails and
                  you still get access to the content, you agree to pay us the
                  corresponding fees within three (3) days of notification from
                  us. We reserve the right to disable access to any content for
                  which we have not received adequate payment.
                </li>
                <li>
                  Lokem may add new services for additional fees and charges, or
                  amend fees and charges for existing services, at any time in
                  its sole discretion. Any change to our pricing or payment
                  terms shall become effective in the billing cycle following
                  notice of such change to you as provided in this Agreement or
                  otherwise.
                </li>
                <li>
                  Lokem subscriptions renew automatically using the payment
                  details on file for your account. If you purchase a
                  subscription with automatic renewal, you acknowledge and agree
                  that we are authorized to use the payment information on file
                  for the renewal fee. Until you cancel, your subscription will
                  renew monthly on the same day of the month, as the date you
                  made your initial purchase and the payment information on file
                  will be billed for the current subscription fee.
                </li>
                <li>
                  You can cancel your subscription at any time by informing the
                  PMO. You must cancel your subscription prior to your next
                  recurring billing date in order to avoid being charged. If you
                  cancel your subscription, you will continue to have access to
                  the platform through the end of your current billing period
                  but will not receive a refund.
                </li>
              </ol>
            </div>
            <div>
              <h2 className="introductionText">Lokem LTD details</h2>
            </div>

            <ol start={33}>
              <div>
                <li>
                  Lokem LTD is a company incorporated in England and Wales with
                  registered number 12134280 whose registered address is Kemp
                  House 160 City Road, London, EC1V 2NX and it operates the
                  Website
                  <a href="https://lokem.co.uk/" target="blank">
                    https://lokem.co.uk
                  </a>
                </li>
                .
              </div>

              <li>You can contact Lokem LTD by email at info@lokem.co.uk.</li>
            </ol>
          </div>
          <div>
            <h2 className="introductionText">Attribution</h2>
          </div>
          <div>
            <p>
              These terms and conditions were created using a document from{" "}
              <a href="https://www.rocketlawyer.com/gb/en/" target="blank">
                Rocket Lawyer
              </a>
              (https://www.rocketlawyer.co.uk).
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default TAndC;
