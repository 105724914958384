import React from "react";
import logo from './immage.jpg';
import { render } from "react-dom";
import '../node_modules/font-awesome/css/font-awesome.min.css';
import AdminLogin from "./AdminLogin";
import AdminTopHeader from "./AdminTopHeader";
import "./AdminTopHeader.css";
import "./Admin.css";
import { Link, Router } from "react-router-dom";

class Admin extends React.Component<{ message: string }, { show: boolean }> {
    render() {
        return (
          <>
     
             <div className="sidebar">
                <div className="logo-details">
         <div className="about-img"></div>
         

                    <span className="logo_name">Slake square</span>
                </div>
                <ul className="nav-links">
    
              <li className="menuItemClick">
            <a href="#" className="">
              <i className="fa fa-users" aria-hidden="true"></i>  <span className="links_name">Trainees</span>
            </a>
          </li>
              <li className="menuItemClick" >
            <a href="#" className="">
              <i className="fa fa-calendar" aria-hidden="true"></i>              <span className="links_name">Add training dates</span>
            </a>
          </li>
              <li className="menuItemClick" >
            <a href="#" className="">
              <i className="fa fa-info-circle" aria-hidden="true"></i>              <span className="links_name">Show training dates</span>
            </a>
          </li>
              <li className="menuItemClick" >
            <a href="#" className="">
              <i className="fa fa-gift"></i>              <span className="links_name">Add coupon</span>
            </a>
          </li>
              <li className="menuItemClick" >
            <a href="#" className="">
              <i className="fa fa-link"></i>              <span className="links_name">Add signup link</span>
            </a>
          </li>
    
    <li className="log_out logoutBtn">
      <a href="#">
        <i className="fa fa-sign-out"></i>
        <span className="links_name">Log out</span>
      </a>
    </li>
  </ul>


   
  </div>

  <div className="home-section" >
  
  <div><AdminTopHeader/></div>
  <div><AdminLogin/></div>

  </div>
  </>
  
            );
        }
      }
      
      export default Admin;
         