import React, { Component } from 'react';
import './admin-login.css';
import './Cookies.css';



class Cookies extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      errors: null
    };
  }

  componentDidMount() {
    // Attach event listeners when component mounts
    const acceptAllBtn = document.getElementById("acceptAllBtn");
    if (acceptAllBtn) {
      acceptAllBtn.addEventListener('click', this.handleAcceptAllClick);
    }

    const savePrefBtn = document.getElementById("savePrefBtn");
    if (savePrefBtn) {
      savePrefBtn.addEventListener('click', this.handleSavePrefClick);
    }
  }

  componentWillUnmount() {
    // Remove event listeners when component unmounts to prevent memory leaks
    const acceptAllBtn = document.getElementById("acceptAllBtn");
    if (acceptAllBtn) {
      acceptAllBtn.removeEventListener('click', this.handleAcceptAllClick);
    }

    const savePrefBtn = document.getElementById("savePrefBtn");
    if (savePrefBtn) {
      savePrefBtn.removeEventListener('click', this.handleSavePrefClick);
    }
  }

  handleAcceptAllClick = () => {
    // Handle accept all button click
    const essCkElements = document.querySelectorAll(".ess-ck");
    essCkElements.forEach(element => {
      element.remove();
    });
    this.checkCookie();
    const cookiePopup = document.getElementById("cookiePopup");
    if (cookiePopup) {
      cookiePopup.remove();
    }
  }

  handleSavePrefClick = () => {
    // Handle save preferences button click
    const essCkElements = document.querySelectorAll(".ess-ck");
    essCkElements.forEach(element => {
      element.remove();
    });
    this.checkCookie();
    const cookiePopup = document.getElementById("cookiePopup");
    if (cookiePopup) {
      cookiePopup.remove();
    }
  }

  
    checkCookie() {
        const cookieAccepted = document.cookie.includes('cookieAccepted=true');
        if (cookieAccepted) {
          // Cookie policy has been accepted
          console.log('Cookie policy accepted');
        } else {
          // Cookie policy has not been accepted
          console.log('Cookie policy not accepted');
        }
      
        // You can add more checks for other cookies as needed
      }
    // Implement your logic for checking cookies
    // This function is not defined in your provided code snippet


  render() {
    return (
        <>
      <div className="manageCookiesMain">
       <div className="cookieHeading"> Manage my cookies</div>
  <div className="cookieReason"> We use cookies to improve the experience. Choose what cookies you allow us to use. You can read more about our cookies policy on our 
    <a className="policyAnchor" href="privacyPolicy" target="blank">privacy policy</a>. </div>
  <div className="cookieLeftRight">
    <div className="cookieLeftSection">
      <div>Essential cookies</div>
      <div className="switchMain">
        <label className="switch">
          <rect className="container" x="1" y="1" width="34" height="18" rx="4"></rect>
          <input type="checkbox" checked/>
          <span className="slider round"></span>
        </label>
        <div className="onoffText">ON</div>
      </div>
      
    </div>
    <div className="cookieRightSection">These are cookies required in order for a website to work and they do not collect any personal information. Lokem uses essential cookies to record when users have viewed cookie notices, and whether the user has granted permission for cookie tracking (essential & non-essential).</div>
  </div>
  <div className="cookieLeftRight">
    <div className="cookieLeftSection">
      <div>Functional cookies</div>
      <div className="switchMain">
        <label className="switch">
          <input type="checkbox" checked/>
          <span className="slider round"></span>
        </label>
        <div className="onoffText">ON</div>
      </div>
    </div>
    <div className="cookieRightSection">These cookies are used to remember choices you make to give you better functionality and personalisation. Lokem uses functional cookies to remember important information and user's preferences. If you do not allow these cookies then some or all of these services may not function properly.</div>
  </div>
  <div className="cookieLeftRight">
    <div className="cookieLeftSection">
      <div>Performance cookies </div>
      <div className="switchMain">
        <label className="switch">
          <input type="checkbox" checked/>
          <span className="slider round"></span>
        </label>
        <div className="onoffText">ON</div>
      </div>
    </div>
    <div className="cookieRightSection">These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular. All information these cookies collect are anonymous. </div>
  </div>
  <div className="cookieLeftRight">
    <div className="cookieLeftSection">
      <div>Marketing cookies</div>
      <div className="switchMain">
        <label className="switch">
          <input type="checkbox" checked/>
          <span className="slider round"></span>
        </label>
        <div className="onoffText">ON</div>
      </div>
    </div>
    <div className="cookieRightSection">Other companies may use cookies on our website, this is known as third party cookie collection. Additional cookies may be essential in order to make these features work correctly, but some companies may also include cookies for tracking and advertising. </div>
  </div>
  <div className="cookieLeftRight">
    <div className="cookieLeftSection">
      <div>Analytics cookies</div>
      <div className="switchMain">
        <label className="switch">
          <input type="checkbox" checked/>
          <span className="slider round"></span>
        </label>
        <div className="onoffText">ON</div>
      </div>
    </div>
    <div className="cookieRightSection">These are used to track how our website is performing. We use Google Analytics to track the number of visits we receive on our website, which specific pages have been visited, and for how long. This information is collected on an anonymous basis. </div>
  </div>
  <div className="cookieLeftRight">
    <div className="cookieLeftSection">
      <div>Third party cookies</div>
      <div className="switchMain">
        <label className="switch">
          <input type="checkbox" checked/>
          <span className="slider round"></span>
        </label>
        <div className="onoffText">ON</div>
      </div>
    </div>
    <div className="cookieRightSection">Other companies may use cookies on our website, this is known as third-party cookie collection. Additional cookies may be essential in order to make these features work correctly, but some companies may also include cookies for tracking and advertising. </div>
  </div>
  <div className="acceptSaveBtn">
    <div className="saveBtn">
      <button className="savePrefBtn lokem-button" id="savePrefBtn"> Save my preferences</button>
    </div>
    <div className="acceptBtn">
      <button className="acceptAllBtn lokem-button" id="acceptAllBtn">Accept all</button>
    </div>
  </div>
      </div>
      </>
    );
  }
}

export default Cookies;